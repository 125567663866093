import AxiosRequest from "../../utils/axios-request";

export default function (product, uid) {
    return Swal.fire({
        title: '¿Estás seguro?',
        text: '',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#30bbe5',
        cancelButtonColor: '#d33',
        cancelButtonText: 'Cancelar',
        confirmButtonText: 'Sí, quitar favorito'
    }).then((function (_this) {
        return function (result) {
            if (result.value) {
                _this.favourite = false;
                AxiosRequest("/customers/favourites/remove", {
                    item_id: product,
                    customer_id: uid
                }, 'post').then(function (_arg) {
                    var data;
                    data = _arg.data;
                    return Swal.fire({
                        title: data.message,
                        icon: 'success',
                        toast: true,
                        position: 'top-end',
                        timer: 2000
                    });
                });
            } else if (result.dismiss === Swal.DismissReason.cancel) {
                Swal.fire({
                    title: 'Cancelado',
                    icon: 'error',
                    toast: true,
                    position: 'top-end',
                    timer: 2000
                }).then(function () {
                    return console.log('Cancelado');
                });
            }
        };
    })(this));
};