// import InfiniteLoading from 'vue-infinite-loading';
import Vue from 'vue/dist/vue.esm';
import TurbolinksAdapter from 'vue-turbolinks';
// import _ from 'lodash';

// FILTERS
import toCurrency from '../filters/to_currency';

// Local imports
import AddCart from '../methods/products/add_cart';
import Delivery from '../methods/products/delivery';
import DeleteFavourite from '../methods/products/delete_favourite';
import Favourite from '../methods/products/favourite';

Vue.use(TurbolinksAdapter);
document.addEventListener('turbolinks:load', () => {
    const el = document.getElementById('show_product');
    if (el == null) return false;
    return new Vue({
        el: el,
        filters: {
            toCurrency: toCurrency
        },
        data: function () {
            return {
                product: [],
                favourites: [],
                favourite: false,
                options: [],
                default_option: [],
                selected_address: void 0,
                selected_option: void 0,
                delivery_type: void 0,
                selected_shipping: void 0,
                selected_pickup: void 0,
                selected_donate: void 0,
                tmp_option_selected: void 0,
                selected_store_id: void 0,
                selected_foundation_id: void 0,
                return_cause: void 0,
                price_shipping: 0,
                quantity: 1,
                min: 1,
                max: 0
            };
        },
        mounted: function () {
            let id, query, title;
            id = document.getElementById('id').dataset.id;
            title = document.getElementById('title').dataset.title;
            query = $.get('/productos/' + title + '-id_' + id + '.json');
            return query.done((res) => {
                this.max = res.product.units;
                this.product = res.product;
                this.options = res.options;
                this.default_option = res.default_option;
                this.favourites = res.favourites;
                return this.productFavourite(res.product.id, res.favourites);
            });
        },
        methods: {
            AddCart: AddCart,
            Delivery: Delivery,
            DeleteFavourite: DeleteFavourite,
            Favourite: Favourite,
            productFavourite: function (product, favourites) {
                return (this.favourite = favourites != null ? favourites.some((e) => e.item_id === product) : 0);
            }
        },
        watch: {
            tmp_option_selected: function (val) {
                this.max = this.options[val].unit;
                this.selected_shipping = false;
                this.selected_donate = false;
                this.selected_pickup = false;
                this.selected_foundation_id = void 0;
                return (this.selected_store_id = void 0);
            },
            max: function (val) {
                if (this.tmp_option_selected !== void 0) {
                    if (this.quantity >= this.max) {
                        return (this.quantity = val);
                    }
                }
            },
            quantity: function (val) {
                if (this.quantity >= this.max) {
                    this.quantity = this.max;
                }
                if (val === 0 || val === null) {
                    return (this.quantity = this.min);
                }
            }
        },
        computed: {
            any_delivery: function () {
                return (
                    this.selected_shipping === true || this.selected_pickup === true || this.selected_donate === true
                );
            }
        }
    });
});