import AxiosRequest from "../../utils/axios-request";

export default function (product, uid) {
    return AxiosRequest('/customers/favourites', {
        item_id: product,
        customer_id: uid
    }, 'post').then((function (_this) {
        return function (_arg) {
            var data;
            data = _arg.data;
            _this.favourite = true;
            return Swal.fire({
                title: data.message,
                icon: 'success',
                toast: true,
                position: 'top-end',
                timer: 2000
            }).then(function () {
                return console.info('success');
            });
        };
    })(this));
};